<template>
  <div>
    <b-container fluid>
      <!-- <div>{{ $v }}</div> -->
      <b-row>
        <b-col lg="3"></b-col>
        <b-col lg="6">
          <b-card header-tag="nav" class="mt-4 mb-4">
            <template v-slot:header>
              <b-navbar toggleable="sm" class="text-center">
                <b-navbar-brand href="#">{{
                  $t("props.title")
                }}</b-navbar-brand>

                <b-navbar-nav>
                  <b-input-group class="mr-2">
                    <b-form-select
                      id="inputSelectOne-live"
                      v-model="$v.inputSelect.$model"
                      :options="$t('props.form.select.options')"
                      ref="focusThis"
                      aria-describedby="inputSelectOne-feedback"
                    ></b-form-select>
                  </b-input-group>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                  <b-link class="ml-3 p-0 text-muted" @click="openSettings">
                    <div class="h3 m-0 p-0">
                      <i class="fas fa-cog"></i>
                    </div>
                  </b-link>
                </b-navbar-nav>
              </b-navbar>
            </template>
            <b-row v-if="this.requestError">
              <b-col md="12">
                <b-alert show variant="danger" class="text-center">{{
                  this.requestError
                }}</b-alert>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-center">
                <h1 class="h2">{{ $t("props.title") }}</h1>
                <hr />
              </b-col>
            </b-row>
            <water v-if="settings.stored.fluids === 'water'" />
          </b-card>
        </b-col>
        <b-col lg="3"></b-col>
      </b-row>
    </b-container>
    <settings />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import water from "./water/index";
import settings from "./settings/index";
export default {
  name: "props.index",
  components: {
    water,
    settings,
  },
  computed: {
    ...mapGetters({
      properties: "getProps",
      settings: "getPropsSettings",
      requestError: "getPropsRequestError",
    }),
    inputSelect: {
      get() {
        return this.properties.select;
      },
      set(value) {
        if (value === "p-t") {
          this.$store.commit("setPropsHi", "");
        } else if (value === "p-h") {
          this.$store.commit("setPropsTi", "");
        } else if (value === "t-h") {
          this.$store.commit("setPropsPi", "");
        }
        this.$store.commit("setPropsSelect", value);
        this.$store.commit("setPropsResult", {});
      },
    },
  },
  validations: {
    inputSelect: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    openSettings() {
      this.$root.$emit("bv::show::modal", "modal-properties-settings");
    },
  },
  created() {
    const query = this.$route.query;
    this.$store.dispatch("getPropsStoreSettings");
    if (query["u"] !== undefined) {
      if (query["u"] === "imperial") {
        this.$store.dispatch("setGlobalUnits", "imperial");
      } else if (query["u"] === "si") {
        this.$store.dispatch("setGlobalUnits", "si");
      } else {
        this.$store.dispatch("setGlobalUnits", "metric");
      }
    }
  },
};
</script>

<style>
</style>