<template>
  <div id="Props.Water">
    <b-row>
      <b-col v-if="properties.select === 'p-t' || properties.select === 'p-h'" md="6">
        <pressure />
      </b-col>
      <b-col v-if="properties.select === 'p-t' || properties.select === 't-h'" md="6">
        <temperature />
      </b-col>
      <b-col v-if="properties.select === 'p-h' || properties.select === 't-h'" md="6">
        <enthalpy />
      </b-col>
    </b-row>
    <result />
  </div>
</template>

<script>
import pressure from "../inputs/pressure";
import temperature from "../inputs/temperature";
import enthalpy from "../inputs/enthalpy";
import result from './result'
import { mapGetters } from 'vuex';
export default {
  name: 'props.water',
  components: {
    pressure,
    temperature,
    enthalpy,
    result
  },
  computed: {
    ...mapGetters({
      properties: "getProps",
    })
  }
};
</script>

<style>
</style>