<template>
  <div id="inletEnthalpy">
    <b-form-group class="mb-2" id="Hi-group">
      <label for="Hi-live">{{$t('props.form.Hi.label')}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="Hi-live"
            tabindex="-1"
            v-model="HiUnit"
            :options="$t('props.form.Hi.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="Hi-live"
          type="number"
          v-model="$v.Hi.$model"
          :state="validateState('Hi')"
          aria-describedby="Hi-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.Hi.$anyError" id="Hi-feedback">
        <small v-if="!$v.Hi.required" class="form-text text-danger">{{$t('props.form.Hi.required')}}</small>
        <small
          v-if="!$v.Hi.valid"
          class="form-text text-danger"
        >{{$t('props.form.Hi.between', {minEnth: getLowerBoundery(HiUnit), maxEnth: getUpperBoundery(HiUnit), unit: $tc('units.enthalpy.short',getEnthalpyUnit(HiUnit))})}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts/";
export default {
  name: "properties.form.inletEnthalpy",
  computed: {
    ...mapGetters({
      properties: "getProps",
    }),
    Hi: {
      get() {
        return this.properties.Hi.value;
      },
      set(value) {
        this.$store.dispatch("storePropsHi", value);
      },
    },
    HiUnit: {
      get() {
        return this.properties.Hi.unit;
      },
      set(value) {
        this.$store.dispatch("storePropsHiUnit", value);
      },
    },
  },
  validations: {
    Hi: {
      required,
      valid() {
        return logic.input.props.enthalpy.validate(
          this.properties.Hi.value,
          this.properties.Hi.unit
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      if (units === "btu_lb") {
        return logic.input.props.enthalpy.btu_lb.min;
      }  else {
        return logic.input.props.enthalpy.kj_kg.min;
      }
    },
    getUpperBoundery(units) {
      if (units === "btu_lb") {
        return logic.input.props.enthalpy.btu_lb.max;
      } else {
        return logic.input.props.enthalpy.kj_kg.max;
      }
    },
    getEnthalpyUnit(units) {
      if (units === "btu_lb") {
        return 1;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>