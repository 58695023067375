<template>
  <div id="props.water.result">
    <div v-if="resultLen() > 0">
      <div v-if="['basic', 'advanced'].includes(settings.stored.detail)">
        <table class="table table-striped table-hover table-responsive-md">
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-center">{{$t('props.output.basic.title')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">{{$t('props.output.basic.table.pressure')}}</td>
              <td class="text-right">
                <b>{{result.P[0]}}</b>
              </td>
              <td>
                <b v-if="result.P[1] === 'psi'">{{$tc('units.pressure.short',1)}}</b>
                <b v-else-if="result.P[1] === 'mpa'">{{$tc('units.pressure.short',2)}}</b>
                <b v-else>{{$tc('units.pressure.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.basic.table.temperature')}}</td>
              <td class="text-right">
                <b>{{result.T[0]}}</b>
              </td>
              <td>
                <b v-if="result.T[1] === 'degf'">{{$tc('units.temperature.short',1)}}</b>
                <b v-else-if="result.T[1] === 'kelvin'">{{$tc('units.temperature.short',2)}}</b>
                <b v-else>{{$tc('units.temperature.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.basic.table.enthalpy')}}</td>
              <td class="text-right">
                <b>{{result.H[0]}}</b>
              </td>
              <td>
                <b v-if="result.H[1] === 'btu/lb'">{{$tc('units.enthalpy.short',1)}}</b>
                <b v-else>{{$tc('units.enthalpy.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.basic.table.entropy')}}</td>
              <td class="text-right">
                <b>{{result.S[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.entropy.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.basic.table.density')}}</td>
              <td class="text-right">
                <b>{{result.rho[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.density.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.basic.table.vaporQaulity')}}</td>
              <td class="text-right">
                <b>{{result.x[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.vaporQuality.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.basic.table.speedOfSound')}}</td>
              <td class="text-right">
                <b>{{result.a[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.velocity.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.basic.table.mu')}}</td>
              <td class="text-right">
                <b>{{Number(result.mu[0]).toExponential()}}</b>
              </td>
              <td>
                <b>{{$tc('units.mu.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.basic.table.criticalPressure')}}</td>
              <td class="text-right">
                <b>{{result.Pc[0]}}</b>
              </td>
              <td>
                <b v-if="result.Pc[1] === 'psi'">{{$tc('units.pressure.short',1)}}</b>
                <b v-else-if="result.Pc[1] === 'mpa'">{{$tc('units.pressure.short',2)}}</b>
                <b v-else>{{$tc('units.pressure.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.basic.table.criticalTemperature')}}</td>
              <td class="text-right">
                <b>{{result.Tc[0]}}</b>
              </td>
              <td>
                <b v-if="result.Tc[1] === 'degf'">{{$tc('units.temperature.short',1)}}</b>
                <b v-else-if="result.Tc[1] === 'kelvin'">{{$tc('units.temperature.short',2)}}</b>
                <b v-else>{{$tc('units.temperature.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.basic.table.Psat')}}</td>
              <td class="text-right">
                <b v-if="result.Psat[0]">{{result.Psat[0]}}</b>
                <b v-else>---</b>
              </td>
              <td>
                <b v-if="result.Psat[1] === 'psi'">{{$tc('units.pressure.short',1)}}</b>
                <b v-else-if="result.Psat[1] === 'mpa'">{{$tc('units.pressure.short',2)}}</b>
                <b v-else>{{$tc('units.pressure.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.basic.table.Tsat')}}</td>
              <td class="text-right">
                <b>{{result.Tsat[0]}}</b>
              </td>
              <td>
                <b v-if="result.Tsat[1] === 'degf'">{{$tc('units.temperature.short',1)}}</b>
                <b v-else-if="result.Tsat[1] === 'kelvin'">{{$tc('units.temperature.short',2)}}</b>
                <b v-else>{{$tc('units.temperature.short',0)}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="['advanced'].includes(settings.stored.detail)">
        <table class="table table-striped table-hover table-responsive-md">
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-center">{{$t('props.output.more.title')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">{{$t('props.output.more.table.cp')}}</td>
              <td class="text-right">
                <b>{{result.Cp[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.cp.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.more.table.cv')}}</td>
              <td class="text-right">
                <b>{{result.Cv[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.cv.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.more.table.k')}}</td>
              <td class="text-right">
                <b>{{result.k[0]}}</b>
              </td>
              <td>
                <b>{{$tc('units.isentropic.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.more.table.Z')}}</td>
              <td class="text-right">
                <b v-if="result.Z[0]">{{result.Z[0]}}</b>
                <b v-else>---</b>
              </td>
              <td>
                <b>{{$tc('units.compressibility.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.more.table.jtc')}}</td>
              <td class="text-right">
                <b v-if="result.jtc[0]">{{result.jtc[0]}}</b>
                <b v-else>---</b>
              </td>
              <td>
                <b>{{$tc('units.jtc.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.more.table.sigma')}}</td>
              <td class="text-right">
                <b v-if="result.sigma[0]">{{result.sigma[0]}}</b>
                <b v-else>---</b>
              </td>
              <td>
                <b>{{$tc('units.sigma.short',0)}}</b>
              </td>
            </tr>
            <tr>
              <td class="text-right">{{$t('props.output.more.table.xkappa')}}</td>
              <td class="text-right">
                <b v-if="result.xkappa[0]">{{result.xkappa[0]}}</b>
                <b v-else>---</b>
              </td>
              <td>
                <b>{{$tc('units.xkappa.short',0)}}</b>
              </td>
              <td class="text-right">{{$t('props.output.more.table.kappas')}}</td>
              <td class="text-right">
                <b v-if="result.kappas[0]">{{result.kappas[0]}}</b>
                <b v-else>---</b>
              </td>
              <td>
                <b>{{$tc('units.kappas.short',0)}}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "props.water.result",
  computed: {
    ...mapGetters({
      result: "getPropsResults",
      settings: "getPropsSettings",
    }),
  },
  methods: {
    resultLen() {
      return Object.keys(this.result).length;
    },
  },
};
</script>

<style>
</style>