<template>
  <div id="inletTemperature">
    <b-form-group class="mb-2" id="Pi-group">
      <label for="Pi-live">{{$t('props.form.Pi.label')}}:</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="Pi-live"
            tabindex="-1"
            v-model="PiUnit"
            :options="$t('props.form.Pi.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="Pi-live"
          type="number"
          v-model="$v.Pi.$model"
          :state="validateState('Pi')"
          aria-describedby="Pi-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.Pi.$anyError" id="Pi-feedback">
        <small v-if="!$v.Pi.required" class="form-text text-danger">{{$t('props.form.Pi.required')}}</small>
        <small v-if="!$v.Pi.valid" class="form-text text-danger">
          {{$t('props.form.Pi.between', {minPres: getLowerBoundery(PiUnit), maxPres: getUpperBoundery(PiUnit), unit: $tc('units.pressure.short',getPressureUnit(PiUnit))})}}
        </small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts";
export default {
  name: "properties.form.inletPressure",
  computed: {
    ...mapGetters({
      properties: "getProps",
    }),
    Pi: {
      get() {
        return this.properties.Pi.value;
      },
      set(value) {
        this.$store.dispatch("storePropsPi", value);
      },
    },
    PiUnit: {
      get() {
        return this.properties.Pi.unit;
      },
      set(value) {
        this.$store.dispatch("storePropsPiUnit", value);
      },
    },
  },
  validations: {
    Pi: {
      required,
      valid() {
        return logic.input.props.pressure.validate(
          this.properties.Pi.value,
          this.properties.Pi.unit
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      if (units === "psi") {
        return logic.input.props.pressure.psi.min;
      } else if (units === "mpa") {
        return logic.input.props.pressure.mpa.min;
      } else {
        return logic.input.props.pressure.bar.min;
      }
    },
    getUpperBoundery(units) {
      if (units === "psi") {
        return logic.input.props.pressure.psi.max;
      } else if (units === "mpa") {
        return logic.input.props.pressure.mpa.max;
      } else {
        return logic.input.props.pressure.bar.max;
      }
    },
    getPressureUnit(units) {
      if (units === "psi") {
        return 1;
      } else if (units === "mpa") {
        return 2;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>